import classNames from 'classnames'
import { forwardRef, type PropsWithChildren, type ReactElement } from 'react'
import { Button, Modal, type ModalProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from '../assets/scss/MaModal.module.scss'

interface MaModalProps extends ModalProps, PropsWithChildren {
  footer?: ReactElement
}

const MaModal = forwardRef(function MaModal (
  {
    show,
    title,
    header,
    footer,
    children,
    className,
    onClose,
    onShow,
    ...props
  }: MaModalProps,
  ref
): ReactElement {
  const { t } = useTranslation()

  return (
    <Modal
      ref={ref}
      show={show}
      onShow={onShow}
      onHide={onClose}
      className={classNames(styles['ma-modal'], className)}
      {...props}
    >
      {header || (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {footer || (
          <Button variant="primary" onClick={onClose}>
            {t('common.button.labels.close')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
})

export default MaModal
