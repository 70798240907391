export const FinanceSystemExports: Record<
string,
{ title: string, url: string }
> = {
  IsSageFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_50',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=1'
  },
  IsXeroFinanceUser: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=2'
  },
  IsDimensionsFinanceUser: {
    title: 'main_nav.purchasing.export_to_dimensions',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=3'
  },
  IsExchequerFinanceUser: {
    title: 'main_nav.purchasing.export_to_exchequer',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=4'
  },
  IsPegasusFinanceUser: {
    title: 'main_nav.purchasing.export_to_pegasus',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=5'
  },
  IsSage200FinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=6'
  },
  IsSageRedXFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=7'
  },
  IsSageSygnatureFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=8'
  },
  IsSageProportionalbyInvoiceLineFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=9'
  },
  IsSageProportionalbyInvoiceFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=10'
  },
  IsAccountIQFinanceUser: {
    title: 'main_nav.purchasing.export_to_account_iq',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=11'
  },
  IsSage200ItaconixFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=13'
  },
  IsSageADCBiotechFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=14'
  },
  IsXeroProprtionalbyInvoiceFinanceUser: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=15'
  },
  IsXeroUSCustomFinanceUser: {
    title: 'main_nav.purchasing.export_to_xero_us',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=16'
  },
  IsNetSuiteUSCustomFinanceUser: {
    title: 'main_nav.purchasing.export_to_netsuite',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=17'
  },
  IsSageProportionalVATShippingbyInvoiceFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=18'
  },
  IsSageAMRFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=19'
  },
  IsSage200BicycleFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_200',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=20'
  },
  IsQuickBooksFinanceUser: {
    title: 'main_nav.purchasing.export_to_quickbooks',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=21'
  },
  IsSage50USFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_50_us',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=22'
  },
  IsSage200MDCFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_50_mdc',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=23'
  },
  IsSage50PsiOxusFinanceUser: {
    title: 'main_nav.purchasing.export_to_sage_50_psioxus',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=24'
  },
  IsXeroByLineFinanceUser: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=25'
  },
  IsNetSuiteFinanceUser: {
    title: 'main_nav.purchasing.export_to_netsuite',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=26'
  },
  IsXeroInvoiceLinesV2FinanceUser: {
    title: 'main_nav.purchasing.export_to_xero',
    url: '/Invoice/ExportInvoiceList.aspx?FinanceSystem=27'
  }
}
