import { type Ref, type ReactElement } from 'react'
import { Card, type CardProps } from 'react-bootstrap'
import classNames from 'classnames'
import styles from '../assets/scss/MaCard.module.scss'

export type MaCardProps = CardProps & {
  forwardedRef?: Ref<HTMLDivElement> | null
}

function MaCard ({
  className,
  forwardedRef,
  ...props
}: MaCardProps): ReactElement {
  return (
    <Card
      {...props}
      className={classNames(styles['ma-card'], className)}
      ref={forwardedRef}
    />
  )
}

MaCard.Img = Card.Img
MaCard.Title = Card.Title
MaCard.Subtitle = Card.Subtitle
MaCard.Body = Card.Body
MaCard.Link = Card.Link
MaCard.Text = Card.Text
MaCard.Header = Card.Header
MaCard.Footer = Card.Footer
MaCard.ImgOverlay = Card.ImgOverlay

export default MaCard
