import {
  type BasketLineItems,
  type BasketLineItem
} from '@amici/myamici-api-client'
import useSWR, { type KeyedMutator } from 'swr'
import { SortDirection } from '../../common/types/sort-direction'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useBasketSettings from './useBasketSettings'

const MAX_PREVIEW_ITEMS = 5

interface UseBasketPreviewHook {
  items: BasketLineItem[]
  mutate: KeyedMutator<BasketLineItems>
  error?: Error
  isLoading: boolean
  isValidating: boolean
}

function useBasketPreview (): UseBasketPreviewHook {
  const { hasNextGenPurchasingEnabled } = useBasketSettings()
  const { activeAccount } = useAccounts()
  const {
    basketApi: { getBasketLineItems },
    fetcher
  } = useApi()

  const basketPreviewParams = {
    page: 1,
    size: MAX_PREVIEW_ITEMS,
    sort: hasNextGenPurchasingEnabled ? 'date_added' : 'line_item_id',
    direction: SortDirection.DESC
  }

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading, isValidating, mutate } = useSWR<
  BasketLineItems,
  Error
  >(
    accountId
      ? [
          'basket-items',
          basketPreviewParams.page,
          basketPreviewParams.size,
          basketPreviewParams.sort,
          basketPreviewParams.direction,
          accountId
        ]
      : null,
    async () =>
      await fetcher(getBasketLineItems, {
        accountId,
        ...basketPreviewParams
      })
  )

  const items = data?.content ?? []

  return { items, mutate, error, isLoading, isValidating }
}

export default useBasketPreview
