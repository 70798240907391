import { type ReactElement, type HTMLAttributes } from 'react'
import {
  type Address,
  type PurchaseOrderResource
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import MaDescriptionList, {
  type DescriptionListItem
} from '../../common/components/MaDescriptionList'
import MaPanel from '../../common/components/MaPanel'
import useDateLocale from '../../common/hooks/useDateLocale'
import useOrderItems from '../hooks/useOrderItems'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'

function formatDeliveryAddress (
  address?: Address,
  companyName?: string
): string {
  if (!address) {
    return ''
  }

  const {
    fao,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_line_3: addressLine3,
    address_line_4: addressLine4,
    address_line_5: addressLine5,
    town,
    county,
    country,
    postal_code: postalCode
  } = address

  const nl = (strings: TemplateStringsArray, str?: string): string =>
    `${str ? `${str}${strings?.[1] ?? ''}\n` : ''}`

  const formattedAddress = (
    nl`${fao},` +
    nl`${companyName}` +
    nl`${addressLine1},` +
    nl`${addressLine2},` +
    nl`${addressLine3},` +
    nl`${addressLine4},` +
    nl`${addressLine5},` +
    nl`${town}` +
    nl`${county}` +
    nl`${country}` +
    nl`${postalCode}`
  ).trim()

  return formattedAddress.endsWith(',')
    ? formattedAddress.slice(0, -1)
    : formattedAddress
}

function PurchaseOrderDetails ({
  order,
  ...props
}: HTMLAttributes<HTMLElement> & {
  order: PurchaseOrderResource
}): ReactElement {
  const { t } = useTranslation()
  const dateLocale = useDateLocale()

  const { estimatedDeliveryDate } = useOrderItems(order?.id)

  const descriptionItems: DescriptionListItem[] = [
    {
      term: t('purchase_order.details.ordered_by'),
      details: order.ordered_by?.name,
      highlight: true
    },
    {
      term: t('purchase_order.details.order_date'),
      details: format(new Date(order.order_date ?? 0), 'do MMMM yyyy', {
        locale: dateLocale
      }),
      highlight: true
    },
    {
      term: t('purchase_order.details.address'),
      details: formatDeliveryAddress(
        order.delivery_address,
        order.ordered_by?.client?.name
      )
    },
    {
      term: t('purchase_order.details.method'),
      details: t(`purchase_order.details.method_name.${order.method}`),
      highlight: true
    },
    {
      term: t('purchase_order.details.supplied_by'),
      details: order.supplier?.name,
      highlight: true
    },
    {
      term: t('purchase_order.details.account_no'),
      details: order.ship_to_account_ref,
      highlight: true
    },
    {
      term: t('purchase_order.details.order_type'),
      details: t(`purchase_order.details.order_type_name.${order.order_type}`),
      highlight: true
    },
    {
      term: t('purchase_order.details.po_note'),
      details: order.po_notes
    }
  ]

  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header>
        <h5 className={styles.title}>
          <span>{t('purchase_order.details.title')}</span>
          <span>
            {t(`order.status.${order.status ?? ''}`, {
              date:
                estimatedDeliveryDate &&
                format(estimatedDeliveryDate, 'do MMMM yyyy')
            })}
          </span>
        </h5>
      </MaPanel.Header>

      <MaPanel.Body className={styles.body}>
        <MaDescriptionList
          className={styles.list}
          items={descriptionItems.slice(
            0,
            Math.ceil(descriptionItems.length / 2)
          )}
        />
        <MaDescriptionList
          className={styles.list}
          items={descriptionItems.slice(Math.ceil(descriptionItems.length / 2))}
        />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderDetails
