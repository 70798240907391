import {
  AccountsApiFactory,
  type BasketApi,
  BasketApiFactory,
  Configuration,
  NotificationsApiFactory,
  type OrdersApi,
  OrdersApiFactory,
  type ProductsApi,
  ProductsApiFactory,
  type ReportsApi,
  ReportsApiFactory,
  type RequestsApi,
  RequestsApiFactory,
  type StocksApi,
  StocksApiFactory,
  type SuppliersApi,
  SuppliersApiFactory,
  TasksApiFactory,
  type SpendCategoriesApi,
  SpendCategoriesApiFactory,
  type AccountsApi,
  type NotificationsApi,
  type TasksApi
} from '@amici/myamici-api-client'
import {
  DefaultApiFactory as SearchApiFactory,
  type DefaultApi as SearchApi
} from '@amici/myamici-search-client'
import { useAuth } from 'react-oidc-context'
import { type AxiosPromise } from 'axios'
import { useEnv } from './useEnv'

interface UseApiHook {
  accountsApi: AccountsApi
  basketApi: BasketApi
  notificationsApi: NotificationsApi
  ordersApi: OrdersApi
  productsApi: ProductsApi
  reportsApi: ReportsApi
  requestsApi: RequestsApi
  searchApi: SearchApi
  stocksApi: StocksApi
  suppliersApi: SuppliersApi
  spendCategoriesApi: SpendCategoriesApi
  tasksApi: TasksApi
  fetcher: <P = any, R = any>(
    method: (params: P) => AxiosPromise<R>,
    params: P,
  ) => Promise<R>
}

function useApi (): UseApiHook {
  const { user } = useAuth()
  const env = useEnv()

  const apiConfig = new Configuration({
    accessToken: user?.access_token,
    ...(!!env.REACT_APP_API_HOST && { basePath: env.REACT_APP_API_HOST })
  })

  const searchApiConfig = new Configuration({
    accessToken: user?.access_token,
    ...(!!env.REACT_APP_SEARCH_HOST && { basePath: env.REACT_APP_SEARCH_HOST })
  })

  async function fetcher<P = any, R = any> (
    method: (params: P) => AxiosPromise<R>,
    params: P
  ): Promise<R> {
    const { data } = await method(params)
    return data
  }

  return {
    accountsApi: AccountsApiFactory(apiConfig) as AccountsApi,
    basketApi: BasketApiFactory(apiConfig) as BasketApi,
    notificationsApi: NotificationsApiFactory(apiConfig) as NotificationsApi,
    ordersApi: OrdersApiFactory(apiConfig) as OrdersApi,
    productsApi: ProductsApiFactory(apiConfig) as ProductsApi,
    reportsApi: ReportsApiFactory(apiConfig) as ReportsApi,
    requestsApi: RequestsApiFactory(apiConfig) as RequestsApi,
    searchApi: SearchApiFactory(searchApiConfig) as SearchApi,
    stocksApi: StocksApiFactory(apiConfig) as StocksApi,
    suppliersApi: SuppliersApiFactory(apiConfig) as SuppliersApi,
    tasksApi: TasksApiFactory(apiConfig) as TasksApi,
    spendCategoriesApi: SpendCategoriesApiFactory(
      apiConfig
    ) as SpendCategoriesApi,
    fetcher
  }
}

export default useApi
