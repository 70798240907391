import { useEnv } from '../../common/hooks/useEnv'
import useAccounts from '../../common/hooks/useAccounts'
import { UserPermission } from '../../common/types/user-permission'
import { type NavigationTarget } from '../../common/types/navigation-target'

function useBasketSettings (): {
  hasNextGenPurchasingEnabled: boolean
  basketUrl: string
  target: NavigationTarget
} {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { activeAccount } = useAccounts()

  const hasNextGenPurchasingEnabled = !!activeAccount?.permissions.includes(
    UserPermission.NextGenPurchasingEnabled
  )
  const basketUrl = hasNextGenPurchasingEnabled
    ? '/purchasing/basket'
    : `${REACT_APP_MA3_BASE_URL ?? ''}/Order/MyBasket.aspx`
  const target: NavigationTarget = hasNextGenPurchasingEnabled
    ? '_self'
    : '_blank'

  return {
    hasNextGenPurchasingEnabled,
    basketUrl,
    target
  }
}

export default useBasketSettings
