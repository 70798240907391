import { useMemo } from 'react'
import useAccounts from './useAccounts'
import { NavigationSection } from '../models/NavigationSection'
import { useEnv } from './useEnv'
import { UserPermission } from '../types/user-permission'
import { UserModule } from '../types/user-module'
import { OrderIntegrationPermission } from '../types/order-integration-permission'
import { FinanceSystemExports } from '../types/finance-system-exports-record'
import { type NavigationTarget } from '../types/navigation-target'

function useMainNavigation (): NavigationSection[] {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { activeAccount } = useAccounts()

  const MA3_BASE_URL: string = REACT_APP_MA3_BASE_URL ?? ''

  const financeSystemIntegrationPermission: string | undefined =
    activeAccount?.permissions.find(permission =>
      Object.keys(FinanceSystemExports).includes(permission)
    )

  const mainNavigation = useMemo<NavigationSection[]>(
    () => [
      new NavigationSection('main_nav.purchasing.title', [
        [
          {
            title: 'main_nav.purchasing.products',
            url: '/purchasing/products',
            isVisible: true
          },
          {
            title: 'main_nav.purchasing.orders',
            url: '/purchasing/orders?searchType=myOrders&filters=status:new;pending;open',
            isVisible: true
          },
          {
            title: 'main_nav.purchasing.endorse_orders',
            url: `${MA3_BASE_URL}/Order/YourOrderList.aspx?Usage=EndorseOrders`,
            target: '_blank',
            isVisible:
              activeAccount?.modules.includes(
                UserModule.HasFinancialControlModule
              ) &&
              activeAccount?.permissions.includes(UserPermission.IsEndorser)
          },
          {
            title: 'main_nav.purchasing.approvals',
            url: `${MA3_BASE_URL}/Order/YourOrderList.aspx?Usage=ApproveOrders`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsApprover
            )
          },
          {
            title: 'main_nav.purchasing.goods_in',
            url: '/purchasing/receive-orders',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsGoodsInUser
            )
          },
          // See MA3 TopBar NetSuiteMessageLog item
          {
            title: 'main_nav.purchasing.message_log',
            url: `${MA3_BASE_URL}/Integration/NetSuiteIntMessageListView.aspx`,
            target: '_blank',
            isVisible:
              (!!activeAccount?.permissions.includes(
                UserPermission.IsContractManagerUser
              ) ||
                !!activeAccount?.permissions.includes(
                  UserPermission.IsFinanceUser
                )) &&
              (activeAccount?.permissions.includes(
                OrderIntegrationPermission.HasNetSuite_IMMIntegration
              ) ||
                activeAccount?.permissions.includes(
                  OrderIntegrationPermission.HasNetSuite_ADAIntegration
                ))
          },
          // See MA3 TopBar NetSuiteMessageLogV2 item
          {
            title: 'main_nav.purchasing.message_log',
            url: `${MA3_BASE_URL}/Integration/NetSuiteIntMessageListViewV2.aspx`,
            target: '_blank',
            isVisible:
              activeAccount?.permissions.includes(
                UserPermission.IsClientIntegrationManager
              ) &&
              (activeAccount?.permissions.includes(
                OrderIntegrationPermission.HasNetSuite_ACHIntegration
              ) ||
                activeAccount?.permissions.includes(
                  OrderIntegrationPermission.HasNetSuite_GYRIntegration
                ))
          },
          // See MA3 TopBar IntegrationModuleMessageLog item
          {
            title: 'main_nav.purchasing.message_log',
            url: `${MA3_BASE_URL}/Integration/NetSuiteIntMessageListViewV2.aspx`,
            target: '_blank',
            isVisible:
              activeAccount?.permissions.includes(
                UserPermission.IsClientIntegrationManager
              ) &&
              activeAccount?.permissions.includes(
                OrderIntegrationPermission.HasIntegrationModuleIntegration
              ) &&
              activeAccount?.modules.includes(UserModule.HasIntegrationModule)
          }
        ],
        [
          {
            title: 'main_nav.purchasing.accruals',
            url: `${MA3_BASE_URL}/Invoice/AccrualsList.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinanceUser
            )
          },
          {
            title: 'main_nav.purchasing.invoices',
            url: `${MA3_BASE_URL}/Invoice/InvoiceList.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinanceUser
            )
          },
          {
            title: 'main_nav.purchasing.invoice_override_used',
            url: `${MA3_BASE_URL}/Invoice/InvoiceOverrideList.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinanceUser
            )
          },
          {
            title: 'main_nav.purchasing.outstanding_orders',
            url: `${MA3_BASE_URL}/Invoice/OutstandingOrdersList.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinanceUser
            )
          },
          {
            title: 'main_nav.purchasing.outstanding_order_items',
            url: '/purchasing/outstanding-order-items',
            isVisible: true
          },
          {
            title: 'main_nav.purchasing.outstanding_proformas',
            url: `${MA3_BASE_URL}/Invoice/OutstandingProformaList.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinanceUser
            )
          },
          ...(financeSystemIntegrationPermission
            ? [
                {
                  title:
                    FinanceSystemExports[financeSystemIntegrationPermission]
                      .title,
                  url: `${MA3_BASE_URL}${FinanceSystemExports[financeSystemIntegrationPermission].url}`,
                  target: '_blank' as NavigationTarget,
                  isVisible: activeAccount?.permissions.includes(
                    UserPermission.IsFinanceUser
                  )
                }
              ]
            : [])
        ]
      ]),
      new NavigationSection('main_nav.logistics.inventory', [
        [
          {
            title: 'main_nav.logistics.inventory',
            url: `${MA3_BASE_URL}/Inventory/InventoryHome.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.modules.includes(UserModule.HasQualityModule) ||
              !!activeAccount?.modules.includes(
                UserModule.HasInventoryModule
              ) ||
              !!activeAccount?.permissions.includes(
                UserPermission.IsQualityInventoryAdmin
              )
          },
          {
            title: 'main_nav.logistics.reserved_stock',
            url: `${MA3_BASE_URL}/Stock/ReservedStockList.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserModule.IsDeliveryModuleUser
              ) ||
              !!activeAccount?.permissions.includes(
                UserPermission.IsAmiciAdministrator
              )
          },
          {
            title: 'main_nav.logistics.restricted_products',
            url: `${MA3_BASE_URL}/Product/ProductRestrictionList.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.modules.includes(
                UserModule.HasHSEControlModule
              ) ||
              !!activeAccount?.permissions.includes(
                UserPermission.IsAmiciAdministrator
              )
          },
          {
            title: 'main_nav.logistics.stock',
            url: `${MA3_BASE_URL}/Stock/StockManagementHome.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsStockUser
              ) ||
              !!activeAccount?.permissions.includes(
                UserPermission.IsAmiciAdministrator
              )
          }
        ]
      ]),
      new NavigationSection('main_nav.assets.title', [
        [
          {
            title: 'main_nav.assets.title',
            url: `${MA3_BASE_URL}/Asset/AssetManager.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              !!(
                activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(UserPermission.IsAssetUser)
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          },
          {
            title: 'main_nav.assets.maintenance_schedule',
            url: `${MA3_BASE_URL}/Asset/ServiceVisitListView.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              !!(
                activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(UserPermission.IsAssetUser)
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          },
          {
            title: 'main_nav.assets.service_contracts',
            url: `${MA3_BASE_URL}/Asset/ServiceContractListView.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              !!(
                activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(UserPermission.IsAssetUser)
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          }
        ],
        [
          {
            title: 'main_nav.assets.asset_visit_cost_report',
            url: `${MA3_BASE_URL}/Asset/AssetServiceVisitCostReportView.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          },
          {
            title: 'main_nav.assets.deprecation_report',
            url: `${MA3_BASE_URL}/Asset/DepreciationReportView.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          },
          {
            title: 'main_nav.assets.service_contract_forecast_report',
            url: `${MA3_BASE_URL}/Asset/ServiceContractForecastCostReportView.aspx`,
            target: '_blank',
            isVisible:
              !!activeAccount?.permissions.includes(
                UserPermission.IsAssetAdmin
              ) ||
              (activeAccount?.modules.includes(UserModule.HasAssetModule) &&
                activeAccount?.permissions.includes(
                  UserPermission.IsAssetManager
                ))
          }
        ]
      ]),
      new NavigationSection('main_nav.settings.title', [
        [
          {
            title: 'main_nav.settings.asset_settings',
            url: `${MA3_BASE_URL}/Asset/ClientAssetMajorAreaSettings.aspx`,
            target: '_blank',
            isVisible:
              activeAccount?.modules.includes(UserModule.HasAssetModule) &&
              activeAccount?.permissions.includes(
                UserPermission.IsAssetManager
              )
          },
          {
            title: 'main_nav.settings.client_account_settings',
            url: `${MA3_BASE_URL}/Admin/ClientDetailGeneral.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsContractManagerUser
            )
          },
          {
            title: 'main_nav.settings.finance_settings',
            url: `${MA3_BASE_URL}/Admin/ClientFinanceCostCentres.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinance
            )
          },
          {
            title: 'main_nav.settings.spend_categories',
            url: '/settings/spend-categories',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsFinance
            )
          }
        ],
        [
          {
            title: 'main_nav.settings.people_ops_settings',
            url: `${MA3_BASE_URL}/PeopleOps/POpsSettingsDefaultContracts.aspx`,
            target: '_blank',
            isVisible: activeAccount?.permissions.includes(
              UserPermission.IsPeopleOpsManager
            )
          },
          {
            title: 'main_nav.settings.stock_settings',
            url: `${MA3_BASE_URL}/Stock/ClientStockGeneralSettings.aspx`,
            target: '_blank',
            isVisible:
              activeAccount?.modules.includes(UserModule.HasStockCheckModule) &&
              activeAccount?.permissions.includes(
                UserPermission.IsStockManager
              )
          },
          {
            title: 'main_nav.settings.reports',
            url: `${MA3_BASE_URL}/Reporting/ReportUserReportListView.aspx`,
            target: '_blank',
            isVisible:
              activeAccount?.modules.includes(UserModule.HasReportingModule) &&
              activeAccount?.permissions.includes(
                UserPermission.IsReportRecipient
              )
          }
        ]
      ])
    ],
    [
      MA3_BASE_URL,
      activeAccount?.modules,
      activeAccount?.permissions,
      financeSystemIntegrationPermission
    ]
  )

  return mainNavigation.filter(section => section.isVisible())
}

export default useMainNavigation
