import './common/assets/scss/App.scss'
import { type ReactElement, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import LoadingScreen from './common/components/LoadingScreen'
import { Alert } from 'react-bootstrap'
import { t } from 'i18next'
import useAccounts from './common/hooks/useAccounts'
import { UserModule } from './common/types/user-module'
import useAppLayout, { LayoutAction } from './common/hooks/useAppLayout'
import QuickAccessContextProvider from './quick-access/components/QuickAccessContextProvider'
import MyAmiciHeader from './common/components/MyAmiciHeader'
import BottomToolbar from './quick-access/components/BottomToolbar'
import QuickAccess from './quick-access/components/QuickAccess'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './errors/ErrorFallback'
import { useSessionStorage } from 'usehooks-ts'
import { UserPermission } from './common/types/user-permission'
import ToastNotificationContextProvider from './common/components/ToastNotificationContextProvider'
import ReactGA from 'react-ga4'

function App (): ReactElement {
  const auth = useAuth()
  const location = useLocation()
  const [authError, setAuthError] = useState<boolean>(false)
  const { layout, dispatch } = useAppLayout()
  const { activeAccount, accountProfile } = useAccounts()

  const activeSignIn = auth.activeNavigator as unknown as boolean

  const [hashCache, setHashCache] = useSessionStorage<string>(
    'login_hash_cache',
    ''
  )

  const activeAccountId = accountProfile?.id
  const clientId = accountProfile?.client?.id

  useEffect(() => {
    if (activeAccountId && clientId) {
      ReactGA.set({
        userId: activeAccountId,
        clientId
      })
    }
  }, [activeAccountId, clientId])

  // Based on the 'react-oidc-context' docs example:
  // https://github.com/authts/react-oidc-context#automatic-sign-in
  useEffect(() => {
    if (authError) return

    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !activeSignIn &&
      !auth.isLoading
    ) {
      setHashCache(window.location.hash)

      auth.signinRedirect().catch(() => {
        setAuthError(true)
      })
    }
  }, [activeSignIn, auth, authError, setHashCache])

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return
    }

    dispatch({ type: LayoutAction.CLOSE_ALL_MENUS })
  }, [auth.isAuthenticated, dispatch, location.pathname])

  if (authError) {
    return (
      <LoadingScreen error={true}>
        <Alert variant="danger">{t('authentication.error')}</Alert>
      </LoadingScreen>
    )
  }

  if (!auth.isAuthenticated || activeSignIn || !activeAccount) {
    return <LoadingScreen />
  }

  if (
    !activeAccount?.modules.includes(UserModule.HasNextGenPurchasingModule) ||
    !activeAccount?.permissions.includes(UserPermission.IsNextGenUser)
  ) {
    return (
      <LoadingScreen>
        <Alert variant="danger">{t('loadingscreen.error.access_denied')}</Alert>
      </LoadingScreen>
    )
  }

  if (hashCache.length > 0 && window.location.hash.length === 0) {
    window.location.hash = hashCache
    setHashCache('')
  }

  // The toolbars are hidden on the Notifications page
  const showToolbars = location.pathname !== '/notifications'

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div
        className="app-wrapper"
        style={{
          ['--left-sidebar-width' as string]: `${layout.quickAccessSidebarWidthPx}px`
        }}
      >
        <MyAmiciHeader
          offcanvasNavVisible={layout.showOffcanvasNav}
          onOpenOffcanvasNav={() => {
            dispatch({ type: LayoutAction.OPEN_OFFCANVAS_NAV })
          }}
          onCloseOffcanvasNav={() => {
            dispatch({ type: LayoutAction.CLOSE_OFFCANVAS_NAV })
          }}
        />

        <QuickAccessContextProvider>
          <ToastNotificationContextProvider>
            <main className="d-flex flex-row">
              {showToolbars && (
                <QuickAccess
                  expanded={layout.quickAccessExpanded}
                  onExpand={() => {
                    dispatch({ type: LayoutAction.EXPAND_QUICK_ACCESS })
                  }}
                  onCollapse={() => {
                    dispatch({ type: LayoutAction.COLLAPSE_QUICK_ACCESS })
                  }}
                />
              )}

              <div className="content">
                <Outlet />
              </div>
            </main>

            {showToolbars && (
              <BottomToolbar
                offcanvasVisible={layout.showBottomOffcanvasMenu}
                onOpenOffcanvas={() => {
                  dispatch({ type: LayoutAction.OPEN_BOTTOM_OFFCANVAS_MENU })
                }}
                onCloseOffcanvas={() => {
                  dispatch({ type: LayoutAction.CLOSE_BOTTOM_OFFCANVAS_MENU })
                }}
              />
            )}
          </ToastNotificationContextProvider>
        </QuickAccessContextProvider>
      </div>
    </ErrorBoundary>
  )
}

export default App
