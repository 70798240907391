import {
  type FormEvent,
  Fragment,
  type ReactElement,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Offcanvas, Row } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BsXLg } from 'react-icons/bs'
import { type DateRange } from 'react-day-picker'
import { addMonths, format } from 'date-fns'
import classNames from 'classnames'
import { type FacetFilters } from '../types/facet-filters'
import { OrderSearchType } from '../types/order-search-type'
import { OrderSortType } from '../types/order-sort-type'
import filtersMapToParam from '../utils/filters-map-to-param'
import filtersQueryStringToMap from '../utils/filters-query-string-to-map'
import useIsMobile from '../../common/hooks/useIsMobile'
import useOrders from '../hooks/useOrders'
import useOrderFacets from '../hooks/useOrderFacets'
import useOrdersPageState, {
  OrdersPageActions
} from '../hooks/useOrdersPageState'
import MaPageTitle from '../../common/components/MaPageTitle'
import MaDateRangePicker from '../../common/components/MaDateRangePicker'
import MaIconButton from '../../common/components/MaIconButton'
import DynamicPagination from '../../common/components/DynamicPagination'
import OrderCard from '../components/OrderCard'
import SearchFilters from '../components/SearchFilters'
import OrderSortSelect from '../components/OrderSortSelect'
import OrderSearchSummary from '../components/OrderSearchSummary'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import OrderSearchForm from '../components/OrderSearchForm'
import { MaSelectItem } from '../../common/components/MaSelect'
import styles from '../assets/scss/SearchPage.module.scss'

function Orders (): ReactElement | null {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [queryParams] = useSearchParams()

  const term = queryParams.get('term') ?? ''
  const orderSortType =
    (queryParams.get('sort') as OrderSortType) ?? OrderSortType.OrderDate
  const orderSearchType =
    (queryParams.get('searchType') as OrderSearchType) ??
    OrderSearchType.MyOrders

  const dateFrom = queryParams.get('dateFrom') ?? undefined
  const dateTo = queryParams.get('dateTo') ?? undefined
  const filters = queryParams.get('filters') ?? ''

  const orderDateRange: DateRange | undefined =
    dateFrom && dateTo
      ? {
          from: new Date(dateFrom),
          to: new Date(dateTo)
        }
      : undefined

  const filtersMap: FacetFilters = useMemo(
    () => filtersQueryStringToMap(filters),
    [filters]
  )

  const { ordersPageState, dispatch } = useOrdersPageState({
    term,
    filters: filtersMap,
    filtersUpdated: false,
    showOffcanvasFilters: false
  })

  const { data, isLoading } = useOrders({
    term,
    page: parseInt(queryParams.get('page')?.toString() ?? '1', 10),
    sort: orderSortType,
    orderSearchType,
    dateFrom,
    dateTo,
    facetFilter: filtersMapToParam(filtersMap)
  })

  const { initialFacets, availableFacets } = useOrderFacets(
    term,
    orderSearchType,
    filtersMapToParam(ordersPageState.filters),
    orderDateRange
  )

  const lastUpdatedCategory = useRef<
  | {
    name: string
    value: Record<string, number>
  }
  | undefined
  >()

  const patchedAvailableFacets = {
    ...availableFacets,
    ...(ordersPageState.filters.size > 0 &&
      lastUpdatedCategory.current && {
      [lastUpdatedCategory.current.name]: lastUpdatedCategory.current.value
    })
  }

  useEffect(() => {
    clearLastUpdatedCategory()

    dispatch({
      type: OrdersPageActions.PATCH_STATE,
      value: {
        term,
        filters: filtersMap
      }
    })

    if (dateFrom === undefined && dateTo === undefined) {
      const newQueryParams = new URLSearchParams(queryParams)

      const defaultDateFrom = format(addMonths(new Date(), -3), 'yyyy-MM-dd')
      const defaultDateTo = format(new Date(), 'yyyy-MM-dd')

      newQueryParams.set('dateFrom', defaultDateFrom)
      newQueryParams.set('dateTo', defaultDateTo)

      navigate(`?${newQueryParams.toString()}`, { replace: true })
    }
  }, [dateFrom, dateTo, dispatch, filtersMap, navigate, queryParams, term])

  const clearLastUpdatedCategory = (): void => {
    lastUpdatedCategory.current = undefined
  }

  const handlePageChange = (page: number): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('page', page.toString())
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleDateRangeChange = (range: DateRange | undefined): void => {
    const newQueryParams = new URLSearchParams(queryParams)

    if (range?.from) {
      newQueryParams.set('dateFrom', format(range.from, 'yyyy-MM-dd'))
    } else {
      newQueryParams.set('dateFrom', '')
    }

    if (range?.to) {
      newQueryParams.set('dateTo', format(range.to, 'yyyy-MM-dd'))
    } else if (range?.from) {
      newQueryParams.set('dateTo', format(range.from, 'yyyy-MM-dd'))
    } else {
      newQueryParams.set('dateTo', '')
    }

    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSearchTypeChange = (searchType: OrderSearchType): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('searchType', searchType)
    newQueryParams.set('page', '1')
    newQueryParams.delete('filters')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSortChange = (value: OrderSortType): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('sort', value)
    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    updateSearchParams()
  }

  const handleApplyFilters = (): void => {
    updateSearchParams()
  }

  const handleFilterUpdate = (
    category: string,
    value: string,
    enabled: boolean
  ): void => {
    const updatedFilters: FacetFilters = new Map(ordersPageState.filters)

    if (!updatedFilters.get(category)) {
      updatedFilters.set(category, new Set())
    }

    if (category !== lastUpdatedCategory.current?.name) {
      lastUpdatedCategory.current = {
        name: category,
        value: availableFacets[category] ?? {}
      }
    }

    const updatedCategory = new Set(updatedFilters.get(category) as Set<string>)

    if (enabled) {
      updatedCategory.add(value)
    } else {
      updatedCategory.delete(value)
    }

    if (updatedCategory.size < 1) {
      updatedFilters.delete(category)
    } else {
      updatedFilters.set(category, updatedCategory)
    }

    if (updatedFilters.size < 1) {
      clearLastUpdatedCategory()
    }

    dispatch({
      type: OrdersPageActions.UPDATE_FILTERS,
      value: updatedFilters
    })
  }

  const handleClearFilters = (): void => {
    clearLastUpdatedCategory()

    dispatch({ type: OrdersPageActions.CLEAR_FILTERS })

    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.delete('filters')
    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const isNewSearch: boolean = ordersPageState.term !== term

  const updateSearchParams = (): void => {
    clearLastUpdatedCategory()

    const newQueryParams = new URLSearchParams(queryParams)
    const filtersParam = filtersMapToParam(ordersPageState.filters)

    newQueryParams.set('sort', orderSortType)
    newQueryParams.set('page', '1')
    filtersParam && filtersParam.length > 0
      ? newQueryParams.set('filters', filtersParam.toString())
      : newQueryParams.delete('filters')

    if (isNewSearch) {
      dispatch({ type: OrdersPageActions.CLEAR_FILTERS })
      newQueryParams.set('term', ordersPageState.term.trim())
      newQueryParams.delete('filters')
    }

    dispatch({
      type: OrdersPageActions.SET_STATE,
      value: {
        ...ordersPageState,
        filtersUpdated: false,
        showOffcanvasFilters: false,
        term: ordersPageState.term.trim()
      }
    })

    navigate(`?${newQueryParams.toString()}`)
  }

  const emptyFacets: boolean = Object.values(initialFacets ?? {})
    .map(category => Object.values(category))
    .every(values => values.length < 1)

  const filterNameFormatters = useMemo(
    () => ({
      status: (category: string, name: string): string =>
        t(`order.search.filter.${category}.${name}`),
      managed_order: (category: string, name: string): string =>
        t(`order.search.filter.${category}.${name}`)
    }),
    [t]
  )

  const categoryFilterEntriesSort = useMemo(() => {
    return {
      ordered_by: (
        entries: Array<Record<string, number>>
      ): Array<Record<string, number>> => {
        return [...entries].sort((a, b) => {
          const [nameA] = Object.keys(a)
          const [nameB] = Object.keys(b)
          return nameA.localeCompare(nameB)
        })
      },
      status: (
        entries: Array<Record<string, number>>
      ): Array<Record<string, number>> => {
        return [
          entries.find(entry => Object.keys(entry)[0] === 'new'),
          entries.find(entry => Object.keys(entry)[0] === 'pending'),
          entries.find(entry => Object.keys(entry)[0] === 'open'),
          entries.find(entry => Object.keys(entry)[0] === 'complete'),
          entries.find(entry => Object.keys(entry)[0] === 'cancelled'),
          entries.find(entry => Object.keys(entry)[0] === 'rejected')
        ].filter(entry => !!entry) as Array<Record<string, number>>
      }
    }
  }, [])

  const searchTypeOptions = [
    {
      value: OrderSearchType.MyOrders,
      label: t('order.filters.select.my_orders')
    },
    {
      value: OrderSearchType.AllOrders,
      label: t('order.filters.select.all_orders')
    }
  ]

  const sortOptions = (): ReactElement => {
    return (
      <Fragment>
        <MaSelectItem value={OrderSortType.OrderDate}>
          {t('order.sort.select.reference')}
        </MaSelectItem>
        <MaSelectItem value={OrderSortType.OrderedBy}>
          {t('order.sort.select.ordered_by')}
        </MaSelectItem>
      </Fragment>
    )
  }

  return (
    <Container
      fluid="auto"
      className={classNames('ma-page', { [styles.mobile]: isMobile })}
    >
      <MaPageTitle>{t('orders.title')}</MaPageTitle>

      <div className={styles['search-form-row']}>
        <OrderSearchForm
          className={styles['search-input']}
          search={ordersPageState.term}
          type={orderSearchType}
          onTypeChange={handleSearchTypeChange}
          onSubmit={handleSubmit}
          searchTypeOptions={searchTypeOptions}
          onSearchChange={e => {
            dispatch({
              type: OrdersPageActions.UPDATE_SEARCH_TERM,
              value: e.target.value
            })
          }}
        />
        {!isMobile && (
          <div className={styles['search-options']}>
            <MaDateRangePicker
              value={orderDateRange}
              onDateRangeChange={handleDateRangeChange}
              placeholder={t('order.filters.date_range.placeholder')}
            />
          </div>
        )}
      </div>

      <div className={styles['search-results']}>
        {!isMobile && (
          <div className={styles.facets}>
            {!emptyFacets && (
              <SearchFilters
                facets={initialFacets}
                availableFacets={patchedAvailableFacets}
                enabledFilters={ordersPageState.filters}
                expandedCategories={[Object.keys(initialFacets)[0]]}
                canApply={ordersPageState.filtersUpdated && !isNewSearch}
                categoryNameFormatter={category =>
                  t(`order.search.filter.${category.toLowerCase()}`)
                }
                filterNameFormatters={filterNameFormatters}
                categoryFilterEntriesSort={categoryFilterEntriesSort}
                onFiltersApply={handleApplyFilters}
                onFiltersClear={handleClearFilters}
                onFilterChange={handleFilterUpdate}
              />
            )}
          </div>
        )}

        {isMobile && (
          <Offcanvas
            className={styles['offcanvas-filters']}
            show={ordersPageState.showOffcanvasFilters}
            placement="start"
            backdrop={false}
            responsive="md"
            onHide={() => {
              dispatch({ type: OrdersPageActions.CLOSE_OFFCANVAS_FILTERS })
            }}
          >
            <Offcanvas.Header className={styles['offcanvas-filters-header']}>
              <h2 className={styles['offcanvas-filters-title']}>
                {t('product.search.sort_filter')}
              </h2>
              <MaIconButton
                onClick={() => {
                  dispatch({
                    type: OrdersPageActions.CLOSE_OFFCANVAS_FILTERS
                  })
                }}
              >
                <BsXLg size={24} />
              </MaIconButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className={classNames(styles.sorting, {
                  [styles.mobile]: isMobile
                })}
              >
                <MaDateRangePicker
                  value={orderDateRange}
                  onDateRangeChange={handleDateRangeChange}
                  placeholder={t('order.filters.date_range.placeholder')}
                />
                <OrderSortSelect
                  value={orderSortType}
                  onChange={handleSortChange}
                  sortOptions={sortOptions()}
                />
              </div>
              {!emptyFacets && (
                <SearchFilters
                  facets={initialFacets}
                  availableFacets={patchedAvailableFacets}
                  enabledFilters={ordersPageState.filters}
                  expandedCategories={[Object.keys(initialFacets)[0]]}
                  canApply={ordersPageState.filtersUpdated && !isNewSearch}
                  categoryNameFormatter={category =>
                    t(`order.search.filter.${category.toLowerCase()}`)
                  }
                  filterNameFormatters={filterNameFormatters}
                  categoryFilterEntriesSort={categoryFilterEntriesSort}
                  onFiltersApply={handleApplyFilters}
                  onFiltersClear={handleClearFilters}
                  onFilterChange={handleFilterUpdate}
                />
              )}
            </Offcanvas.Body>
          </Offcanvas>
        )}

        <div className={styles['card-grid']}>
          {isMobile && (
            <div className={styles['search-controls']}>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="light"
                    className={styles['offcanvas-filters-toggle-btn']}
                    onClick={() => {
                      dispatch({
                        type: OrdersPageActions.OPEN_OFFCANVAS_FILTERS
                      })
                    }}
                  >
                    {t('product.search.sort_filter')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <Row>
            <Col>
              <OrderSearchSummary
                total={data?.totalElements}
                dateRange={orderDateRange}
                filters={filtersMap ?? undefined}
                term={term}
              />
            </Col>

            {!isMobile && (
              <Col>
                <div className={styles.sorting}>
                  <OrderSortSelect
                    value={orderSortType}
                    onChange={handleSortChange}
                    sortOptions={sortOptions()}
                  />
                </div>
              </Col>
            )}
          </Row>

          <div data-testid="orders">
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}

            <Row>
              {data?.content?.map(order => (
                <Col xs={12} key={order.id}>
                  <OrderCard order={order} />
                </Col>
              ))}
            </Row>
          </div>

          {data && (data?.totalElements as number) > 0 && (
            <div className={styles.pagination}>
              <DynamicPagination
                currentPage={data?.page ?? 0}
                totalPages={data?.totalPages ?? 0}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Orders
