import {
  forwardRef,
  type PropsWithChildren,
  type HTMLAttributes,
  type ReactElement
} from 'react'
import * as Select from '@radix-ui/react-select'
import classNames from 'classnames'
import { BsCheck, BsChevronDown } from 'react-icons/bs'
import '../assets/scss/MaSelect.scss'

export interface MaSelectProps extends Select.SelectProps, PropsWithChildren {
  className?: string
  placeholder?: string
  label?: string
  ['aria-label']?: string
  ['data-testid']?: string
  onCloseAutoFocus?: (event: Event) => void
}

export function MaSelect ({
  placeholder,
  label,
  className,
  children,
  onCloseAutoFocus,
  ...props
}: MaSelectProps): ReactElement {
  return (
    <Select.Root {...props}>
      <Select.Trigger
        className={classNames('ma-select-trigger', className)}
        aria-label={props['aria-label']}
        data-testid={props['data-testid']}
      >
        <Select.Value placeholder={placeholder} />
        <Select.Icon className="ma-select-icon">
          <BsChevronDown />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          position="popper"
          className="ma-select-content"
          onCloseAutoFocus={(e: Event) => {
            onCloseAutoFocus?.(e)
          }}
          ref={ref =>
            ref?.addEventListener('touchend', e => {
              e.preventDefault()
            })
          }
        >
          <Select.ScrollUpButton />
          <Select.Viewport className="ma-select-viewport">
            <Select.Group>
              {label && (
                <Select.Label className="ma-select-label">{label}</Select.Label>
              )}
              {children}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton />
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
export const MaSelectItem = forwardRef<
HTMLDivElement,
Select.SelectItemProps & HTMLAttributes<HTMLElement>
>(function MaSelectItem (
  { children, className, ...props },
  forwardedRef
): ReactElement<HTMLDivElement> {
  return (
    <Select.Item
      className={classNames('ma-select-item', className)}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="ma-select-item-indicator">
        <BsCheck />
      </Select.ItemIndicator>
    </Select.Item>
  )
})
