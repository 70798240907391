import { type ReactElement } from 'react'
import { type BasketLineItem } from '@amici/myamici-api-client'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/OrderRequestSummary.module.scss'

interface OrderRequestSummaryProps {
  items: BasketLineItem[]
  selectedItemIds: string[]
}
function OrderRequestSummary ({
  items,
  selectedItemIds
}: OrderRequestSummaryProps): ReactElement {
  const { t } = useTranslation()

  const selectedItems = items.filter(({ line_item: item }) =>
    selectedItemIds.includes(item.id)
  )

  const noSelectedItems = selectedItems.length < 1

  const suppliers = [
    ...selectedItems.reduce(
      (suppliers, item) =>
        suppliers.add(item.line_item?.product.supplier?.name ?? ''),
      new Set<string>()
    )
  ]
    .filter(s => !!s)
    .sort((a, b) => a.localeCompare(b))

  const currencies = [
    ...selectedItems.reduce(
      (currencies, item) => currencies.add(item.line_item.currency ?? 'GBP'),
      new Set<string>()
    )
  ].sort((a, b) => a.localeCompare(b))

  const getSupplierCurrencies = (supplier: string): string[] => [
    ...selectedItems
      .filter(({ line_item: item }) => item.product.supplier?.name === supplier)
      .reduce(
        (currencies, item) => currencies.add(item.line_item.currency ?? 'GBP'),
        new Set<string>()
      )
  ]

  const getSupplierItems = (
    supplier: string,
    currency: string
  ): BasketLineItem[] =>
    selectedItems.filter(
      ({ line_item: item }) =>
        item.currency === currency && item.product.supplier?.name === supplier
    )

  const getSupplierTotal = (supplier: string, currency: string): number =>
    getSupplierItems(supplier, currency).reduce(
      (total, item) =>
        total + (item.product_price ?? 0) * item.line_item.quantity,
      0
    )

  const getEstimatedDeliveryCharge = (
    supplier: string,
    currency: string
  ): number => {
    const items = getSupplierItems(supplier, currency)

    if (
      items.some(
        ({ line_item: item }) =>
          item.currency !== (item.product.supplier?.currency ?? 'GBP')
      )
    ) {
      // for some items product currency does not match supplier currency
      return -1
    }

    const productCharge = Math.max(
      ...items.map(
        ({ line_item: { product } }) => product.carriage_charge ?? 0
      )
    )
    const supplierCharge = Math.max(
      ...items.map(
        ({
          line_item: {
            product: { supplier }
          }
        }) => supplier?.standard_carriage_charge ?? 0
      )
    )

    return productCharge || supplierCharge
  }

  const getTotal = (currency: string): number =>
    suppliers.reduce(
      (total, supplier) =>
        total +
        getSupplierTotal(supplier, currency) +
        (getEstimatedDeliveryCharge(supplier, currency) > 0
          ? getEstimatedDeliveryCharge(supplier, currency)
          : 0),
      0
    )

  return (
    <MaPanel className={styles['order-request']}>
      <MaPanel.Header className={styles.header}>
        <h5>{t('basket.request_summary.title')}</h5>
      </MaPanel.Header>

      <MaPanel.Body className={styles.body}>
        {noSelectedItems && (
          <p>{t('basket.request_summary.no_selected_items')}</p>
        )}

        {suppliers.map(supplier => (
          <section key={supplier} className={styles.supplier}>
            <h6>{supplier}</h6>

            {getSupplierCurrencies(supplier).map(currency => (
              <p key={`${supplier}-${currency}-items`}>
                <span>
                  {t('basket.request_summary.item', {
                    count: getSupplierItems(supplier, currency).length
                  })}
                </span>

                <span>
                  {t('common.price', {
                    price: getSupplierTotal(supplier, currency),
                    currency
                  })}
                </span>
              </p>
            ))}

            {getSupplierCurrencies(supplier).map((currency, index) => (
              <p key={`${supplier}-${currency}-delivery`}>
                <span>
                  {index === 0 && t('basket.request_summary.delivery_charge')}
                </span>

                <span>
                  {getEstimatedDeliveryCharge(supplier, currency) < 0
                    ? t('basket.request_summary.delivery_charge.unknown')
                    : getEstimatedDeliveryCharge(supplier, currency) === 0
                      ? t('basket.request_summary.delivery_charge.free')
                      : t('common.price', {
                        price: getEstimatedDeliveryCharge(supplier, currency),
                        currency
                      })}
                </span>
              </p>
            ))}
          </section>
        ))}
      </MaPanel.Body>

      <MaPanel.Footer className={styles.footer}>
        <div className={styles.total}>
          {currencies.map((currency, index) => (
            <p key={`${currency}-total`}>
              <span>{index === 0 && t('basket.request_summary.total')}</span>

              <span>
                {t('common.price', { price: getTotal(currency), currency })}
              </span>
            </p>
          ))}
        </div>

        <Button
          variant="primary"
          href="/"
          className={classNames('rounded', styles.create)}
          disabled={noSelectedItems}
        >
          {t('basket.request_summary.create_request')}
        </Button>
      </MaPanel.Footer>
    </MaPanel>
  )
}

export default OrderRequestSummary
