import React, { type HTMLAttributes, type ReactElement } from 'react'
import classNames from 'classnames'
import styles from '../assets/scss/MaDescriptionList.module.scss'

export interface DescriptionListItem {
  term: string
  details?: string | ReactElement | null
  highlight?: boolean
}

interface MaDescriptionListProps extends HTMLAttributes<HTMLDListElement> {
  items: DescriptionListItem[]
}

function MaDescriptionList ({
  items,
  className,
  ...props
}: MaDescriptionListProps): ReactElement {
  return (
    <dl
      role="list"
      className={classNames(className, styles['ma-description-list'])}
      {...props}
    >
      {items.map(item => (
        <React.Fragment key={item.term}>
          <dt className={styles.term}>{item.term}</dt>
          <dd className={styles.details}>
            {item.highlight ? <strong>{item.details}</strong> : item.details}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  )
}

export default MaDescriptionList
