import {
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement
} from 'react'
import classNames from 'classnames'
import styles from '../assets/scss/MaPanel.module.scss'

export function MaPanelHeader ({
  text,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> &
PropsWithChildren & { text?: string }): ReactElement {
  return (
    <div
      className={classNames(styles['panel-header'], className)}
      {...props}
      data-testid="panel-header"
    >
      {text && <h4 className={styles.title}>{text}</h4>}

      {!text && children}
    </div>
  )
}

export function MaPanelBody ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> & PropsWithChildren): ReactElement {
  return (
    <div
      className={classNames(styles['panel-body'], className)}
      {...props}
      data-testid="panel-body"
    >
      {children}
    </div>
  )
}

export function MaPanelFooter ({
  text,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> &
PropsWithChildren & { text?: string }): ReactElement {
  return (
    <div
      className={classNames(styles['panel-footer'], className)}
      {...props}
      data-testid="panel-footer"
    >
      {text || children}
    </div>
  )
}

function MaPanel ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> & PropsWithChildren): ReactElement {
  return (
    <section
      className={classNames(styles.panel, className)}
      {...props}
      data-testid="panel"
    >
      {children}
    </section>
  )
}

MaPanel.Header = MaPanelHeader
MaPanel.Body = MaPanelBody
MaPanel.Footer = MaPanelFooter

export default MaPanel
