import { type ReactElement, useContext } from 'react'
import { Navbar, Offcanvas } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsBell, BsListCheck, BsStar, BsXLg } from 'react-icons/bs'
import {
  QuickAccessActions,
  type QuickAccessTabs
} from '../hooks/useQuickAccessState'
import useTasks from '../../tasks/hooks/useTasks'
import useFavourites from '../../favourites/hooks/useFavourites'
import useUnreadNotifications from '../../notifications/hooks/useUnreadNotifications'
import useIsMobile from '../../common/hooks/useIsMobile'
import {
  QuickAccessContext,
  QuickAccessDispatchContext
} from './QuickAccessContextProvider'
import BasketIcon from '../../basket/components/BasketIcon'
import MaIconButton from '../../common/components/MaIconButton'
import Favourites from '../../favourites/components/Favourites'
import Tasks from '../../tasks/components/Tasks'
import Notifications from '../../notifications/components/Notifications'
import styles from '../assets/scss/BottomToolbar.module.scss'

interface BottomToolbarProps {
  offcanvasVisible: boolean
  onOpenOffcanvas: () => void
  onCloseOffcanvas: () => void
}

function BottomToolbar ({
  offcanvasVisible,
  onOpenOffcanvas,
  onCloseOffcanvas
}: BottomToolbarProps): ReactElement | null {
  const quickAccessState = useContext(QuickAccessContext)
  const quickAccessDispatch = useContext(QuickAccessDispatchContext)
  const { t } = useTranslation()
  const { totalTasks } = useTasks()
  const { totalFavourites } = useFavourites()
  const notificationsCount = useUnreadNotifications()
  const isMobile = useIsMobile()

  const handleOffcanvasToggle = (tab: QuickAccessTabs): void => {
    quickAccessDispatch({
      type: QuickAccessActions.SET_ACTIVE_TAB,
      value: tab
    })

    if (offcanvasVisible && quickAccessState.activeTab === tab) {
      onCloseOffcanvas()
    } else {
      onOpenOffcanvas()
    }
  }

  if (!isMobile) {
    return null
  }

  return (
    <>
      <Navbar fixed="bottom" className={styles['bottom-toolbar']}>
        <MaIconButton
          variant="secondary"
          activeBarPosition="top"
          active={offcanvasVisible && quickAccessState.activeTab === 'tasks'}
          count={totalTasks}
          onClick={() => {
            handleOffcanvasToggle('tasks')
          }}
          data-testid="offcanvas-tasks-toggle-btn"
        >
          <BsListCheck size={24} />
        </MaIconButton>

        <BasketIcon />

        <MaIconButton
          variant="secondary"
          activeBarPosition="top"
          active={
            offcanvasVisible && quickAccessState.activeTab === 'favourites'
          }
          onClick={() => {
            handleOffcanvasToggle('favourites')
          }}
          data-testid="offcanvas-favourites-toggle-btn"
        >
          <BsStar size={24} />
        </MaIconButton>

        <MaIconButton
          variant="secondary"
          count={notificationsCount}
          activeBarPosition="top"
          active={
            offcanvasVisible && quickAccessState.activeTab === 'notifications'
          }
          onClick={() => {
            handleOffcanvasToggle('notifications')
          }}
          data-testid="offcanvas-notifications-toggle-btn"
        >
          <BsBell size={24} />
        </MaIconButton>
      </Navbar>

      <Offcanvas
        show={offcanvasVisible}
        placement="bottom"
        backdrop={false}
        onHide={onCloseOffcanvas}
        className={styles['offcanvas-menu']}
      >
        <Offcanvas.Header className={styles['offcanvas-header']}>
          <h3 className={styles.title}>
            {t(`quick_access.tab.title.${quickAccessState.activeTab ?? ''}`)}
            {quickAccessState.activeTab === 'favourites' &&
              totalFavourites > 0 &&
              ` (${totalFavourites})`}
          </h3>
          <MaIconButton
            className={styles['close-btn']}
            onClick={onCloseOffcanvas}
            data-testid="offcanvas-menu-close-btn"
          >
            <BsXLg size={24} />
          </MaIconButton>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles['offcanvas-body']}>
          {quickAccessState.activeTab === 'tasks' && <Tasks />}
          {quickAccessState.activeTab === 'favourites' && <Favourites />}
          {quickAccessState.activeTab === 'notifications' && <Notifications />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default BottomToolbar
