import { type Dispatch, useReducer } from 'react'
import { type FacetFilters } from '../types/facet-filters'
import ReactGA from 'react-ga4'

export interface OrdersPageState {
  term: string
  filters: FacetFilters
  filtersUpdated: boolean
  showOffcanvasFilters: boolean
}

export enum OrdersPageActions {
  UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  RESET_FILTERS_UPDATED_FLAG = 'RESET_FILTERS_UPDATED_FLAG',
  OPEN_OFFCANVAS_FILTERS = 'OPEN_OFFCANVAS_FILTERS',
  CLOSE_OFFCANVAS_FILTERS = 'CLOSE_OFFCANVAS_FILTERS',
  SET_STATE = 'SET_STATE',
  PATCH_STATE = 'PATCH_STATE',
  RESET_STATE = 'RESET_STATE',
}

function reducer (
  state: OrdersPageState,
  action: { type: OrdersPageActions, value?: any }
): OrdersPageState {
  switch (action.type) {
    case OrdersPageActions.UPDATE_SEARCH_TERM: {
      return {
        ...state,
        term: action.value
      }
    }
    case OrdersPageActions.UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.value,
        filtersUpdated: true
      }
    }
    case OrdersPageActions.CLEAR_FILTERS: {
      ReactGA.event('clear_search_filters')
      return {
        ...state,
        filters: new Map(),
        filtersUpdated: false
      }
    }
    case OrdersPageActions.RESET_FILTERS_UPDATED_FLAG: {
      ReactGA.event('reset_search_filters')
      return {
        ...state,
        filtersUpdated: false
      }
    }
    case OrdersPageActions.OPEN_OFFCANVAS_FILTERS: {
      ReactGA.event('open_component', {
        component_type: 'offcanvas',
        component_id: 'search_filters'
      })
      return {
        ...state,
        showOffcanvasFilters: true
      }
    }
    case OrdersPageActions.CLOSE_OFFCANVAS_FILTERS: {
      ReactGA.event('close_component', {
        component_type: 'offcanvas',
        component_id: 'search_filters'
      })
      return {
        ...state,
        showOffcanvasFilters: false
      }
    }
    case OrdersPageActions.SET_STATE: {
      return {
        ...action.value
      }
    }
    case OrdersPageActions.PATCH_STATE: {
      return {
        ...state,
        ...action.value
      }
    }
    case OrdersPageActions.RESET_STATE: {
      return {
        term: '',
        filters: new Map(),
        filtersUpdated: false,
        showOffcanvasFilters: false
      }
    }
    default: {
      return state
    }
  }
}

function useOrdersPageState (state: OrdersPageState): {
  ordersPageState: OrdersPageState
  dispatch: Dispatch<{
    type: OrdersPageActions
    value?: any
  }>
} {
  const [ordersPageState, dispatch] = useReducer(reducer, state)

  return {
    ordersPageState,
    dispatch
  }
}

export default useOrdersPageState
