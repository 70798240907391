export enum UserPermission {
  CanChangePassword = 'CanChangePassword',
  IsAmiciAdministrator = 'IsAmiciAdministrator',
  IsApprover = 'IsApprover',
  IsAssetAdmin = 'IsAssetAdmin',
  IsAssetManager = 'IsAssetManager',
  IsAssetUser = 'IsAssetUser',
  IsAudited = 'IsAudited',
  IsClientIntegrationManager = 'IsClientIntegrationManager',
  IsContractManagerUser = 'IsContractManagerUser',
  IsEndorser = 'IsEndorser',
  IsFinance = 'IsFinance',
  IsFinanceUser = 'IsFinanceUser',
  IsGoodsInUser = 'IsGoodsInUser',
  IsHazardApprover = 'IsHazardApprover',
  IsNextGenUser = 'IsNextGenUser',
  IsPeopleOpsManager = 'IsPeopleOpsManager',
  IsQualityInventoryAdmin = 'IsQualityInventoryAdmin',
  IsReportRecipient = 'IsReportRecipient',
  IsStockManager = 'IsStockManager',
  IsStockUser = 'IsStockUser',
  NextGenPurchasingEnabled = 'NextGenPurchasingEnabled',
}
