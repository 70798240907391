import { type RouteObject } from 'react-router-dom'
import { UserPermission } from '../common/types/user-permission'
import ProtectedRoute from '../common/components/ProtectedRoute'
import SpendCategories from '../spend-categories/pages/SpendCategories'

const purchasingRoutes: RouteObject[] = [
  {
    path: 'settings',
    children: [
      {
        path: 'spend-categories',
        element: (
          <ProtectedRoute requiredPermissions={[UserPermission.IsFinance]}>
            <SpendCategories />
          </ProtectedRoute>
        )
      }
    ]
  }
]

export default purchasingRoutes
