import { type RouteObject } from 'react-router-dom'
import Products from './pages/Products'
import ProductDetails from './pages/ProductDetails'
import Orders from './pages/Orders'
import OutstandingOrderItems from '../outstanding-order-items/components/OutstandingOrderItems'
import PurchaseOrder from './pages/PurchaseOrder'
import ReceiveOrders from './pages/ReceiveOrders'
import ProtectedRoute from '../common/components/ProtectedRoute'
import Basket from '../basket/pages/Basket'
import { UserPermission } from '../common/types/user-permission'

const purchasingRoutes: RouteObject[] = [
  {
    path: 'purchasing',
    children: [
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'products/:productId',
        element: <ProductDetails />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'orders/:orderId',
        element: <PurchaseOrder />
      },
      {
        path: 'outstanding-order-items',
        element: <OutstandingOrderItems />
      },
      {
        path: 'receive-orders',
        element: (
          <ProtectedRoute requiredPermissions={[UserPermission.IsGoodsInUser]}>
            <ReceiveOrders />
          </ProtectedRoute>
        )
      },
      {
        path: 'basket',
        element: (
          <ProtectedRoute
            requiredPermissions={[UserPermission.NextGenPurchasingEnabled]}
          >
            <Basket />
          </ProtectedRoute>
        )
      }
    ]
  }
]

export default purchasingRoutes
