import { type Dispatch, useReducer } from 'react'
import { SortDirection } from '../../common/types/sort-direction'

export type QuickAccessTabs = 'tasks' | 'notifications' | 'favourites'

export enum QuickAccessActions {
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  CHANGE_FAVOURITES_SEARCH = 'CHANGE_FAVOURITES_SEARCH',
  CHANGE_TASKS_SEARCH = 'CHANGE_TASKS_SEARCH',
  CHANGE_TASKS_SORTING_ORDER = 'CHANGE_TASKS_SORTING_ORDER',
}

interface QuickAccessState {
  activeTab: QuickAccessTabs
  tasksSearch: string
  tasksSortingOrder: SortDirection
  favouritesSearch: string
}

export const quickAccessState: QuickAccessState = {
  activeTab: 'tasks',
  tasksSearch: '',
  tasksSortingOrder: SortDirection.ASC,
  favouritesSearch: ''
}

function quickAccessStateReducer (
  state: QuickAccessState,
  action: {
    type: QuickAccessActions
    value: string
  }
): QuickAccessState {
  switch (action.type) {
    case QuickAccessActions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.value as QuickAccessTabs
      }
    case QuickAccessActions.CHANGE_TASKS_SEARCH:
      return {
        ...state,
        tasksSearch: action.value
      }
    case QuickAccessActions.CHANGE_TASKS_SORTING_ORDER:
      return {
        ...state,
        tasksSortingOrder: action.value as SortDirection
      }
    case QuickAccessActions.CHANGE_FAVOURITES_SEARCH:
      return {
        ...state,
        favouritesSearch: action.value
      }
    default:
      return state
  }
}

interface UseQuickAccessStateHook {
  state: QuickAccessState
  dispatch: Dispatch<{
    type: QuickAccessActions
    value: string
  }>
}

function useQuickAccessState (): UseQuickAccessStateHook {
  const [state, dispatch] = useReducer(
    quickAccessStateReducer,
    quickAccessState
  )

  return {
    state,
    dispatch
  }
}

export default useQuickAccessState
