import {
  type FormEvent,
  type ReactElement,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Col, Container, Offcanvas, Row } from 'react-bootstrap'
import { type Product } from '@amici/myamici-search-client'
import { useTranslation } from 'react-i18next'
import { BsXLg } from 'react-icons/bs'
import classNames from 'classnames'
import { type FacetFilters } from '../types/facet-filters'
import { ProductSearchType } from '../types/product-search-type'
import { ProductSortType } from '../types/product-sort-type'
import filtersQueryStringToMap from '../utils/filters-query-string-to-map'
import filtersMapToParam from '../utils/filters-map-to-param'
import useIsMobile from '../../common/hooks/useIsMobile'
import useProductSearch, {
  type ProductSearchParams
} from '../hooks/useProductSearch'
import useProductFacets from '../hooks/useProductFacets'
import useProductsPageState, {
  ProductsPageActions
} from '../hooks/useProductsPageState'
import useProductDetails from '../hooks/useProductDetails'
import MaPageTitle from '../../common/components/MaPageTitle'
import DynamicPagination from '../../common/components/DynamicPagination'
import ProductSearchForm from '../components/ProductSearchForm'
import SearchFilters from '../components/SearchFilters'
import ProductSearchSummary from '../components/ProductSearchSummary'
import ProductCard from '../components/ProductCard'
import MaIconButton from '../../common/components/MaIconButton'
import ProductSearchSortSelect from '../components/ProductSearchSortSelect'
import RecentlyPurchasedProducts from '../components/RecentlyPurchasedProducts'
import FrequentlyPurchasedProducts from '../components/FrequentlyPurchasedProducts'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/SearchPage.module.scss'

function Products (): ReactElement {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()

  const productId = queryParams.get('productId') ?? ''

  const { data: productData } = useProductDetails(productId)

  const altSearchTerm = productData?.description
  const term = queryParams.get('term') ?? ''
  const filters = queryParams.get('filters') ?? ''

  const isAltSearch: boolean = !!(!term && productId && altSearchTerm)

  const filtersMap: FacetFilters = useMemo(
    () => filtersQueryStringToMap(filters),
    [filters]
  )

  const { data, isLoading, searchParams, setSearchParams } = useProductSearch()
  const { productsPageState, dispatch } = useProductsPageState({
    term,
    type:
      (queryParams.get('type') as ProductSearchType) ??
      ProductSearchType.Keyword,
    filters: filtersMap,
    filtersUpdated: false,
    showOffcanvasFilters: false
  })

  const { initialFacets, availableFacets } = useProductFacets(
    searchParams.term,
    searchParams.type,
    filtersMapToParam(productsPageState.filters),
    searchParams.productId
  )

  const lastUpdatedCategory = useRef<
  | {
    name: string
    value: Record<string, number>
  }
  | undefined
  >()

  const clearLastUpdatedCategory = (): void => {
    lastUpdatedCategory.current = undefined
  }

  const patchedAvailableFacets = {
    ...availableFacets,
    ...(productsPageState.filters.size > 0 &&
      lastUpdatedCategory.current && {
      [lastUpdatedCategory.current.name]: lastUpdatedCategory.current.value
    })
  }

  useEffect(() => {
    clearLastUpdatedCategory()

    const type: ProductSearchType = isAltSearch
      ? ProductSearchType.Alternatives
      : (queryParams.get('type') as ProductSearchType) ??
        ProductSearchType.Keyword

    const newParams: ProductSearchParams = {
      productId,
      term,
      type,
      page: parseInt(queryParams.get('page')?.toString() ?? '1', 10),
      sort:
        (queryParams.get('sort') as ProductSortType) ?? ProductSortType.Default,
      pageSize: parseInt(queryParams.get('pageSize')?.toString() ?? '12', 10),
      facetFilter: filtersMapToParam(filtersMap)
    }

    dispatch({
      type: ProductsPageActions.PATCH_STATE,
      value: {
        term: newParams.term,
        type: isAltSearch ? ProductSearchType.Keyword : newParams.type,
        filters: filtersMap
      }
    })

    setSearchParams(newParams)
  }, [
    queryParams,
    productId,
    altSearchTerm,
    term,
    isAltSearch,
    filtersMap,
    dispatch,
    setSearchParams
  ])

  const isNewSearch: boolean =
    productsPageState.term !== searchParams.term ||
    (!isAltSearch && productsPageState.type !== searchParams.type)

  const handlePageChange = (page: number): void => {
    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('page', page.toString())
    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSortChange = (sort: string): void => {
    if (!isAltSearch && !searchParams.term) {
      setSearchParams({
        ...searchParams,
        sort: sort as ProductSortType
      })

      return
    }

    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.set('sort', sort)
    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const updateSearchParams = (): void => {
    clearLastUpdatedCategory()

    const newQueryParams = new URLSearchParams(queryParams)
    const filtersParam = filtersMapToParam(productsPageState.filters)

    // We need to end the existing alternatives search flow
    // when the new search flow has started
    if (isNewSearch) {
      newQueryParams.set('type', productsPageState.type)
      newQueryParams.set('term', productsPageState.term.trim())
      newQueryParams.delete('productId')
    }

    newQueryParams.set('sort', searchParams.sort as string)
    newQueryParams.set('page', '1')
    filtersParam && filtersParam.length > 0
      ? newQueryParams.set('filters', filtersParam.toString())
      : newQueryParams.delete('filters')

    if (isNewSearch) {
      dispatch({ type: ProductsPageActions.CLEAR_FILTERS })
      newQueryParams.delete('filters')
    }

    dispatch({
      type: ProductsPageActions.SET_STATE,
      value: {
        ...productsPageState,
        filtersUpdated: false,
        showOffcanvasFilters: false,
        term: productsPageState.term.trim()
      }
    })

    navigate(`?${newQueryParams.toString()}`)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!productsPageState.term) {
      return
    }

    updateSearchParams()
  }

  const handleApplyFilters = (): void => {
    updateSearchParams()
  }

  const handleFilterUpdate = (
    category: string,
    value: string,
    enabled: boolean
  ): void => {
    const updatedFilters: FacetFilters = new Map(productsPageState.filters)

    if (!updatedFilters.get(category)) {
      updatedFilters.set(category, new Set())
    }

    if (category !== lastUpdatedCategory.current?.name) {
      lastUpdatedCategory.current = {
        name: category,
        value: availableFacets[category] ?? {}
      }
    }

    const updatedCategory = new Set(updatedFilters.get(category) as Set<string>)

    if (enabled) {
      updatedCategory.add(value)
    } else {
      updatedCategory.delete(value)
    }

    if (updatedCategory.size < 1) {
      updatedFilters.delete(category)
    } else {
      updatedFilters.set(category, updatedCategory)
    }

    if (updatedFilters.size < 1) {
      clearLastUpdatedCategory()
    }

    dispatch({
      type: ProductsPageActions.UPDATE_FILTERS,
      value: updatedFilters
    })
  }

  const handleClearFilters = (): void => {
    clearLastUpdatedCategory()

    dispatch({ type: ProductsPageActions.CLEAR_FILTERS })

    const newQueryParams = new URLSearchParams(queryParams)
    newQueryParams.delete('filters')
    newQueryParams.set('page', '1')
    navigate(`?${newQueryParams.toString()}`)
  }

  const emptyFacets: boolean = Object.values(initialFacets ?? {})
    .map(category => Object.values(category))
    .every(values => values.length < 1)

  const filterNameFormatters = useMemo(
    () => ({
      supplier_status: (category: string, name: string): string =>
        t(`product.search.filter.${category}.${name}`),
      previously_purchased: (category: string, name: string): string =>
        t(`product.search.filter.${category}.${name}`),
      PackSizeAndUnit: (category: string, name: string): string => {
        const [size, unit] = name.split(/\s/)
        return (
          size + ' ' + t([`units.${unit}`, unit], { count: parseFloat(size) })
        )
      }
    }),
    [t]
  )

  const categoryFilterEntriesSort = useMemo(
    () => ({
      PackSizeAndUnit: (entries: Array<Record<string, number>>) =>
        [...entries].sort((entryA, entryB) => {
          const [nameA] = Object.keys(entryA)
          const [nameB] = Object.keys(entryB)

          const [sizeA, unitA] = nameA.split(/\s/)
          const [sizeB, unitB] = nameB.split(/\s/)

          if (unitA.localeCompare(unitB) === 0) {
            return Number(sizeA) - Number(sizeB)
          }

          return unitA.localeCompare(unitB)
        })
    }),
    []
  )

  return (
    <Container
      fluid="auto"
      className={classNames('ma-page', { [styles.mobile]: isMobile })}
    >
      <MaPageTitle>{t('products.title')}</MaPageTitle>

      <Row className={styles['search-form-row']}>
        <ProductSearchForm
          className={styles['search-input']}
          search={productsPageState.term}
          type={productsPageState.type}
          onSubmit={handleSubmit}
          onSearchChange={e => {
            dispatch({
              type: ProductsPageActions.UPDATE_SEARCH_TERM,
              value: e.target.value
            })
          }}
          onTypeChange={searchType => {
            dispatch({
              type: ProductsPageActions.UPDATE_SEARCH_TYPE,
              value: searchType
            })
          }}
        />
      </Row>

      {(isLoading ||
        data?.totalElements !== undefined ||
        searchParams.term) && (
        <div className={styles['search-results']}>
          {!isMobile && (
            <div className={styles.facets}>
              {!emptyFacets && (
                <SearchFilters
                  facets={initialFacets}
                  availableFacets={patchedAvailableFacets}
                  enabledFilters={productsPageState.filters}
                  expandedCategories={[Object.keys(initialFacets)[0]]}
                  canApply={productsPageState.filtersUpdated && !isNewSearch}
                  categoryNameFormatter={category =>
                    t(`product.search.filter.${category.toLowerCase()}`)
                  }
                  filterNameFormatters={filterNameFormatters}
                  categoryFilterEntriesSort={categoryFilterEntriesSort}
                  onFiltersApply={handleApplyFilters}
                  onFiltersClear={handleClearFilters}
                  onFilterChange={handleFilterUpdate}
                />
              )}
            </div>
          )}

          {isMobile && (
            <Offcanvas
              className={styles['offcanvas-filters']}
              show={productsPageState.showOffcanvasFilters}
              placement="start"
              backdrop={false}
              responsive="md"
              onHide={() => {
                dispatch({ type: ProductsPageActions.CLOSE_OFFCANVAS_FILTERS })
              }}
            >
              <Offcanvas.Header className={styles['offcanvas-filters-header']}>
                <h2 className={styles['offcanvas-filters-title']}>
                  {t('product.search.sort_filter')}
                </h2>
                <MaIconButton
                  onClick={() => {
                    dispatch({
                      type: ProductsPageActions.CLOSE_OFFCANVAS_FILTERS
                    })
                  }}
                >
                  <BsXLg size={24} />
                </MaIconButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div
                  className={classNames(styles.sorting, {
                    [styles.mobile]: isMobile
                  })}
                >
                  <ProductSearchSortSelect
                    value={searchParams.sort}
                    onValueChange={handleSortChange}
                  />
                </div>

                {!emptyFacets && (
                  <SearchFilters
                    facets={initialFacets}
                    availableFacets={patchedAvailableFacets}
                    enabledFilters={productsPageState.filters}
                    expandedCategories={[]}
                    canApply={productsPageState.filtersUpdated && !isNewSearch}
                    categoryNameFormatter={category =>
                      t(`product.search.filter.${category.toLowerCase()}`)
                    }
                    filterNameFormatters={filterNameFormatters}
                    categoryFilterEntriesSort={categoryFilterEntriesSort}
                    onFiltersApply={handleApplyFilters}
                    onFiltersClear={handleClearFilters}
                    onFilterChange={handleFilterUpdate}
                  />
                )}
              </Offcanvas.Body>
            </Offcanvas>
          )}

          <div className={styles['card-grid']}>
            {isMobile && (
              <div className={styles['search-controls']}>
                <Row>
                  <Col xs={12}>
                    <Button
                      variant="light"
                      className={styles['offcanvas-filters-toggle-btn']}
                      onClick={() => {
                        dispatch({
                          type: ProductsPageActions.OPEN_OFFCANVAS_FILTERS
                        })
                      }}
                    >
                      {t('product.search.sort_filter')}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col>
                <ProductSearchSummary
                  totalElements={data?.totalElements}
                  searchTerm={searchParams.term}
                  alternativesFor={altSearchTerm ?? ''}
                />
              </Col>

              {!isMobile && (
                <Col>
                  <div
                    className={classNames(
                      styles.sorting,
                      styles['product-search-sort']
                    )}
                  >
                    <ProductSearchSortSelect
                      value={searchParams.sort}
                      onValueChange={handleSortChange}
                    />
                  </div>
                </Col>
              )}
            </Row>

            <div data-testid="product-items">
              {isLoading && (
                <div className={styles.loading}>
                  <LoadingSpinner />
                </div>
              )}

              <Row>
                {!isLoading &&
                  data?.results?.map((product: Product) => (
                    <Col lg={12} xxl={6} key={product.id} className="g-4">
                      <ProductCard
                        product={product}
                        currentPage={data?.page ?? 0}
                      />
                    </Col>
                  ))}
              </Row>
            </div>

            {data && (data?.totalPages as number) > 0 && (
              <div className={styles.pagination}>
                <DynamicPagination
                  currentPage={data?.page ?? 0}
                  totalPages={data?.totalPages ?? 0}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!isLoading &&
        data?.totalElements === undefined &&
        !searchParams.term && (
          <>
            {!isMobile && (
              <div
                className={classNames(
                  styles.sorting,
                  styles['product-search-sort']
                )}
              >
                <ProductSearchSortSelect
                  className={styles['results-pre-sort']}
                  value={searchParams.sort}
                  onValueChange={handleSortChange}
                />
              </div>
            )}
            <RecentlyPurchasedProducts />
            <FrequentlyPurchasedProducts />
          </>
      )}
    </Container>
  )
}

export default Products
