import { type ReactElement, useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { type FacetFilters } from '../types/facet-filters'
import useIsMobile from '../../common/hooks/useIsMobile'
import SearchFiltersCategory from './SearchFiltersCategory'
import AppliedFilters from './AppliedFilters'
import styles from '../assets/scss/SearchFilters.module.scss'

interface SearchFiltersProps {
  facets: Record<string, Record<string, number>>
  availableFacets: Record<string, Record<string, number>>
  enabledFilters: FacetFilters
  expandedCategories: string[]
  canApply: boolean
  categoryNameFormatter: (category: string) => string
  filterNameFormatters?: Record<
  string,
  (category: string, name: string) => string
  >
  categoryFilterEntriesSort?: Record<
  string,
  (
    entries: Array<Record<string, number>>,
    category?: string,
    formatter?: (category: string, name: string) => string,
  ) => Array<Record<string, number>>
  >
  onFilterChange: (category: string, value: string, enabled: boolean) => void
  onFiltersApply: () => void
  onFiltersClear: () => void
}

function SearchFilters ({
  facets,
  availableFacets,
  enabledFilters,
  expandedCategories = [],
  canApply,
  categoryNameFormatter,
  filterNameFormatters = {},
  categoryFilterEntriesSort,
  onFilterChange,
  onFiltersApply,
  onFiltersClear
}: SearchFiltersProps): ReactElement | null {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [textFilters, setTextFilters] = useState<Record<string, string>>({})

  useEffect(() => {
    setTextFilters({})
  }, [facets])

  const handleTextFiltersChange = (category: string, value: string): void => {
    setTextFilters(filters => ({
      ...filters,
      [category]: value
    }))
  }

  if (!facets) {
    return null
  }

  return (
    <>
      <div
        className={classNames(styles['action-buttons'], {
          [styles.mobile]: isMobile
        })}
      >
        <Button
          onClick={onFiltersApply}
          variant="primary"
          className="rounded"
          disabled={!canApply}
        >
          {t('common.button.labels.apply')}
        </Button>
      </div>

      <div className={styles['filters-wrapper']}>
        <AppliedFilters
          filters={enabledFilters}
          onFilterChange={onFilterChange}
          categoryNameFormatter={categoryNameFormatter}
          filterNameFormatters={filterNameFormatters}
          onFiltersClear={() => {
            setTextFilters({})
            onFiltersClear()
          }}
        />

        <Accordion defaultActiveKey={expandedCategories} alwaysOpen>
          {Object.entries(facets).map(([category, options]): ReactElement => {
            const availableOptions = availableFacets[category] ?? {}
            const enabledOptions = enabledFilters.get(category) ?? new Set()
            const textFilter = textFilters[category] ?? ''

            return (
              <SearchFiltersCategory
                key={category}
                category={category}
                initialOptions={options}
                availableOptions={availableOptions}
                enabledOptions={enabledOptions}
                textFilter={textFilter}
                categoryNameFormatter={categoryNameFormatter}
                filterNameFormatter={filterNameFormatters[category]}
                filterEntriesSort={categoryFilterEntriesSort?.[category]}
                onFilterChange={onFilterChange}
                onTextFilterChange={handleTextFiltersChange}
              />
            )
          })}
        </Accordion>
      </div>
    </>
  )
}

export default SearchFilters
