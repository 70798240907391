import { type ChangeEvent, type FormEvent, type ReactElement, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import classNames from 'classnames'
import styles from '../assets/scss/MaAddQuantity.module.scss'

interface MaAddQuantityProps {
  label: string
  ctaLabel: string
  disabled: boolean
  min?: number
  max?: number
  className?: string
  onSubmit: (quantity: number) => void
}

function MaAddQuantity ({
  label,
  ctaLabel,
  disabled,
  min = 1,
  max = 1000000,
  onSubmit,
  className
}: MaAddQuantityProps): ReactElement {
  const [quantity, setQuantity] = useState<string>('1')

  const submitDisabled = disabled || quantity.toString().length < 1

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseInt(e.target.value, 10)

    if (isNaN(newValue)) {
      setQuantity('')
    } else if (newValue > 0) {
      setQuantity(newValue.toString())
    }
  }

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!submitDisabled) {
      onSubmit(parseInt(quantity, 10))
      setQuantity('1')
    }
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      className={classNames(styles['ma-add-quantity'], className)}
    >
      <Form.Group className={styles['input-wrapper']}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="number"
          value={quantity}
          disabled={disabled}
          min={min}
          max={max}
          onChange={handleOnChange}
        />
      </Form.Group>

      <Button
        type="submit"
        variant="primary"
        className="rounded"
        disabled={submitDisabled}
      >
        {ctaLabel}
      </Button>
    </Form>
  )
}

export default MaAddQuantity
